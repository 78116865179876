var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('edit-component',{attrs:{"name":_vm.name,"loading":_vm.appLoad},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Global.details"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.name'),"label-for":"mc-course"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.year'),"label-for":"mc-course"}},[_c('validation-provider',{attrs:{"name":"term","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","state":errors.length > 0 ? false : null},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.term'),"label-for":"mc-program"}},[_c('validation-provider',{attrs:{"name":"instructors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"id":"mc-program","options":_vm.lookups.terms,"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.term_id),callback:function ($$v) {_vm.$set(_vm.form, "term_id", $$v)},expression:"form.term_id"}},[_c('template',{slot:"no-options"},[(_vm.loadTerms)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Global.loading"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("Global.searchInTerms"))+" ")])])],2),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.faculty'),"label-for":"mc-course"}},[_c('validation-provider',{attrs:{"name":"faculty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"filter":_vm.fuseSearch,"id":"mc-course","options":_vm.lookups.faculties,"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.faculty_id),callback:function ($$v) {_vm.$set(_vm.form, "faculty_id", $$v)},expression:"form.faculty_id"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.bylaws'),"label-for":"mc-course"}},[_c('validation-provider',{attrs:{"name":"bylaw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"filter":_vm.fuseSearch,"id":"mc-course","options":_vm.bylaws || [],"disabled":!_vm.form.faculty_id,"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.bylaw_id),callback:function ($$v) {_vm.$set(_vm.form, "bylaw_id", $$v)},expression:"form.bylaw_id"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.program'),"label-for":"mc-program"}},[_c('validation-provider',{attrs:{"name":"programs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"filter":_vm.fuseSearch,"id":"mc-program","options":_vm.programs || [],"disabled":!_vm.form.bylaw_id,"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.program_id),callback:function ($$v) {_vm.$set(_vm.form, "program_id", $$v)},expression:"form.program_id"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.quota'),"label-for":"mc-max_total","type":"number"}},[_c('validation-provider',{attrs:{"name":"quota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-max_total","state":errors.length > 0 ? false : null,"type":"text"},model:{value:(_vm.form.quota),callback:function ($$v) {_vm.$set(_vm.form, "quota", $$v)},expression:"form.quota"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.pre_exam_fees'),"label-for":"mc-course"}},[_c('validation-provider',{attrs:{"name":"pre_exam_fees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","state":errors.length > 0 ? false : null},model:{value:(_vm.form.pre_exam_fees),callback:function ($$v) {_vm.$set(_vm.form, "pre_exam_fees", $$v)},expression:"form.pre_exam_fees"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1)],1),(_vm.form.term)?_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("Global.offering_settings")))]),_c('hr')]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.num_group'),"label-for":"mc-group"}},[_c('validation-provider',{attrs:{"name":"num_groups","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-group","state":errors.length > 0 ? false : null,"type":"number","readonly":_vm.disable_inputs},model:{value:(_vm.form.num_groups),callback:function ($$v) {_vm.$set(_vm.form, "num_groups", $$v)},expression:"form.num_groups"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}],null,false,1324234140)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.num_section'),"label-for":"mc-section"}},[_c('validation-provider',{attrs:{"name":"num_sections","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-section","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.form.num_sections),callback:function ($$v) {_vm.$set(_vm.form, "num_sections", $$v)},expression:"form.num_sections"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}],null,false,2663878402)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.quota'),"label-for":"mc-quota"}},[_c('validation-provider',{attrs:{"name":"section_quota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-quota","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.form.section_quota),callback:function ($$v) {_vm.$set(_vm.form, "section_quota", $$v)},expression:"form.section_quota"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}],null,false,634307470)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.over_quota'),"label-for":"mc-over_quota"}},[_c('validation-provider',{attrs:{"name":"section_over_quota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-over_quota","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.form.section_over_quota),callback:function ($$v) {_vm.$set(_vm.form, "section_over_quota", $$v)},expression:"form.section_over_quota"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}],null,false,1628453358)})],1)],1)],1):_vm._e(),_c('hr'),_c('h4',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.$t("Global.certificates"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.certificate'),"label-for":"mc-program"}},[_c('validation-provider',{attrs:{"name":"pre_university_certificate_groupe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"filter":_vm.fuseSearch,"options":_vm.certificate_sections,"reduce":function (el) { return el.id; },"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.pre_university_certificate_groupe),callback:function ($$v) {_vm.$set(_vm.form, "pre_university_certificate_groupe", $$v)},expression:"form.pre_university_certificate_groupe"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.pre_university_certificate'),"label-for":"mc-program"}},[_c('validation-provider',{attrs:{"name":"pre_university_certificate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"options":_vm.certificates,"filter":_vm.fuseSearch,"reduce":function (el) { return el.id; },"disabled":!_vm.form.pre_university_certificate &&
                        !_vm.form.pre_university_certificate_groupe,"label":_vm.getSelectLabel(),"clearable":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.pre_university_certificate),callback:function ($$v) {_vm.$set(_vm.form, "pre_university_certificate", $$v)},expression:"form.pre_university_certificate"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Global.pre_university_certificate_type'),"label-for":"mc-program"}},[_c('validation-provider',{attrs:{"name":"pre_university_certificate_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:errors.length > 0 ? 'custom_invalid' : '',attrs:{"options":_vm.certificate_types,"filter":_vm.fuseSearch,"disabled":!_vm.form.pre_university_certificate &&
                        !_vm.form.pre_university_certificate_type,"label":_vm.getSelectLabel(),"multiple":"","clearable":""},on:{"option:selected":_vm.addGrades,"option:deselected":_vm.removeGrades},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(_vm.$i18n.locale == 'ar')?_c('span',[_vm._v(" "+_vm._s(option.name_local || option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.name || option.name_local)+" ")])]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local || option.name : option.name || option.name_local)+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noMatching"))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.pre_university_certificate_type),callback:function ($$v) {_vm.$set(_vm.form, "pre_university_certificate_type", $$v)},expression:"form.pre_university_certificate_type"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}])})],1)],1)],1),(
                _vm.form.pre_university_certificate_type &&
                _vm.form.pre_university_certificate_type.length
              )?_c('b-row',_vm._l((_vm.grades),function(item,index){return _c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":item.name,"label-for":"mc-type"}},[_c('validation-provider',{attrs:{"name":'minimum_grade' + '.' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-type","placeholder":_vm.$t('Global.minimum_grade'),"value":item.minimum_grade,"state":errors.length > 0 ? false : null},on:{"blur":function($event){return _vm.updateItemForm(index, $event, item)}}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validation(null, 0).message))]):_vm._e()]}}],null,true)})],1)],1)}),1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('hr'),_c('h4',{staticClass:"my-1"},[_vm._v(_vm._s(_vm.$t("Global.required_subjects")))])])],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},_vm._l((_vm.options),function(option){return _c('b-form-checkbox',{key:option,attrs:{"id":option.text,"name":option.text,"value":true,"unchecked-value":false},model:{value:(_vm.form[option.text]),callback:function ($$v) {_vm.$set(_vm.form, option.text, $$v)},expression:"form[option.text]"}},[_vm._v(_vm._s(_vm.$t(("Global." + (option.text)))))])}),1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" Submit ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }